<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('lot.lots') }}</h2>
    </div>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}
        </el-button>
      </div>
      <div class="filter-container">
        <!-- <el-radio v-model="activeList" label="actives" border @change="getSubCampagneagricolesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>-->
        <el-radio v-model="activeList" label="desactives" border @change="getSubLotsList('DESACTIVER')">{{
          $t('general.F_desactives')
        }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterLots">{{
          $t('general.disponibles')
        }}</el-radio>
        <el-radio
          v-model="activeList"
          label="corbeille"
          class="danger-radio"
          border
          @change="getSubLotsList('CORBEILLE')"
        >{{ $t('general.corbeille') }}</el-radio>

        <div style="display:flex; flex-direction:row">
          <el-select
            v-model="zoneFilter"
            placeholder="Zone"
            style="margin-right:10px; width:200px"
            @change="filterByZone()"
          >
            <el-option v-for="item in zones" :key="item.value" :label="item.nom" :value="item.id" />
          </el-select>
          <el-select
            v-if="zoneFilter !== null"
            v-model="communeFilter"
            placeholder="Commune"
            style="margin-right:10px; width:200px"
            @change="filterByCommune()"
          >
            <el-option v-for="item in communes" :key="item.id" :label="item.nom" :value="item.id" />
          </el-select>
          <el-select
            v-if="communeFilter !== null"
            v-model="villageFilter"
            placeholder="Village"
            style="margin-right:10px; width:200px"
            @change="filterByVillage()"
          >
            <el-option v-for="item in villages" :key="item.id" :label="item.nom" :value="item.id" />
          </el-select>
        </div>
      </div>
      <hr>
      <div style="display:flex; flex-direction:row">
        <el-select
          v-model="campagneFilter"
          placeholder="Campagne"
          style="margin-right:10px; width:200px"
          @change="filterByCampagne(campagneFilter)">
          <el-option
            v-for="(item, index) in campagnes"
            :key="index"
            :label="item.periode"
            :value="index"
          />
        </el-select>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input
          v-model="query.keyword"
          :placeholder="$t('general.keyword')"
          class="wd-200 mr-15 filter-item"
          @input="handleFilterLots"
        />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterLots">
          {{ $t('general.search') }}
        </el-button>
        <el-radio v-model="activeList" label="enCours" border @change="getSubLotsList('EN COURS')">En Cours
        </el-radio>
        <el-radio v-model="activeList" label="livre" border @change="getSubLotsList('TERMINER')">Livré</el-radio>
        <el-button v-if="checkPermission(['creer lot'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateLot">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <!-- <el-table :data="list" border fit highlight-current-row style="width: 100%"> -->
      <!-- <el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column> -->

      <!-- <el-table-column align="center" :label="$t('general.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column> -->

      <!-- <el-table-column align="center" :label="$t('general.description')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column> -->
      <!-- v-if="activeList!='corbeille' && checkPermission(['activer campagneagricole'])" -->
      <!-- <el-table-column align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setLotStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column> -->

      <!-- <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setLotStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column> -->

      <!-- <el-table-column v-if="checkPermission(['modifier produit','visible produit','supprimer produit'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier produit'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditLot(scope.row.id);" />
            <el-button v-if="checkPermission(['visible produit','supprimer produit'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteLot(scope.row.id, scope.row.is_visible, scope.row.nom);" />
            <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsModal(scope.row)" />
          </template>
        </el-table-column> -->
      <!-- </el-table> -->
      <el-collapse v-if="!loading" v-model="activeName" accordion>
        <el-collapse-item v-for="item in list" :key="item.campagne" :title="item.campagne" name="1">
          <el-table :data="item.lots" border fit highlight-current-row style="width: 100%">
            <el-table-column align="center" label="Code" min-width="100px">
              <template slot-scope="scope">
                <span>{{ scope.row.code }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" label="Société" min-width="200px">
              <template slot-scope="scope">
                <span>{{ scope.row.ste_trans }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" label="Immatriculation" min-width="200px">
              <template slot-scope="scope">
                <span>{{ scope.row.im_camion }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" label="Date chargement" min-width="300px">
              <template slot-scope="scope">
                <span>{{ scope.row.date_chrgmnt_libelle }}</span>
              </template>
            </el-table-column>
            <!-- v-if="activeList!='corbeille' && checkPermission(['activer campagneagricole'])" -->
            <el-table-column align="center" :label="$t('general.status')" width="100">
              <template slot-scope="scope">
                <el-switch
                  style="display: block"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                  :value="scope.row.is_active"
                  @change="setLotStatus(scope.row.id, 'active')"
                />
              </template>
            </el-table-column>

            <el-table-column
              v-if="checkRole(['admin']) && (activeList == 'corbeille' || query.keyword)"
              align="center"
              :label="$t('general.restaurer')"
              width="100"
            >
              <template slot-scope="scope">
                <el-switch
                  v-if="!scope.row.is_visible"
                  style="display: block"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                  :value="scope.row.is_visible"
                  @change="setLotStatus(scope.row.id, 'visible')"
                />
              </template>
            </el-table-column>

            <el-table-column
              v-if="checkPermission(['modifier lot', 'visible lot', 'supprimer lot'])"
              align="center"
              :label="1 == 1 ? $t('general.actions') : $t('general.suppressionDefinitive')"
              width="200"
            >
              <template slot-scope="scope">
                <div class="flex flex-wrap gap-2 my-2">
                  <el-tag v-if="scope.row.status === 'EN COURS'" type="success" class="mx-1" effect="light" round>EN COURS</el-tag>
                  <el-tag v-if="scope.row.status === 'TERMINER'" type="danger" class="mx-1" effect="light" round>LIVRÉ</el-tag>
                </div>
                <el-button
                  v-if="scope.row.is_visible && checkPermission(['modifier lot'])"
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  style="margin-right:10px;"
                  @click="handleEditLot(scope.row.id);"
                />
                <el-button
                  v-if="checkPermission(['visible lot', 'supprimer lot'])"
                  slot="reference"
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  style="margin-right:10px;"
                  @click="handleDeleteLot(scope.row.id, scope.row.is_visible, scope.row.code);"
                />
                <el-button type="info" size="small" icon="el-icon-view" @click="navigateLot(scope.row.id)" />
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <!-- <el-collapse-item title="Feedback" name="2">
          <div>Retour d'expèrience: permets aux utilisateurs de percevoir clairement leur opérations par le biais d'animations et d'effets interactifs.</div>
          <div>Retour visuel: reflète l'état actuel de la page via le réarrangement ou la mise à jour des éléments.</div>
        </el-collapse-item>
        <el-collapse-item title="Efficacité" name="3">
          <div>Simplifier le processus: garde chaque opération simple et intuitive.;</div>
          <div>Clair et défini: énonce clairement ses intentions afin que l'utilisateur puisse comprendre et prendre une décision rapidement;</div>
          <div>Facile à identifier: l'interface devrait être simple et facile d'accès, afin que les utilisateurs n'ai pas d'efforts de mémorisation à faire.</div>
        </el-collapse-item>
        <el-collapse-item title="Controllabilité" name="4">
          <div>Prise de décision: possibilité de donner des conseils sur les opérations, mais ne jamais prendre de décisions à la place des utilisateurs;</div>
          <div>Contrôle des conséquences: les utilisateurs devraient pouvoir contrôler l'exécution de leurs opérations, y compris l'annulation et la suppression des opérations courantes.</div>
        </el-collapse-item> -->
      </el-collapse>
      <div v-loading="loading" />
      <el-empty v-if="list === null && !loading" description="Aucune donnée" />

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getLotsList" />

      <!-- ======= REGION USER DIALOG ======= -->

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>

    <el-dialog
      :title="editing ? $t('lot.edit') + ' ' + currentLot.code : $t('lot.AddLot')"
      :visible.sync="dialogCreateLot"
      :before-close="handleCloseCampagneagricoleCreateDraw"
      :loading="lotCreating"
      width="30%"
    >
      <div class="content-inputs">
        <el-form ref="lotForm" :rules="rules" :model="currentLot" label-position="left">
          <el-form-item :label="$t('lot.ste_trans')" prop="ste_trans">
            <el-input v-model="currentLot.ste_trans" />
          </el-form-item>
          <el-form-item :label="$t('lot.im_camion')" prop="im_camion">
            <el-input v-model="currentLot.im_camion" type="textarea" />
          </el-form-item>
          <el-form-item :label="$t('lot.date_chrgmnt')" prop="date_chrgmnt">
            <div class="block">
              <el-date-picker
                v-model="currentLot.date_chrgmnt"
                type="datetime"
                format="dd-MM-yyyy HH:mm:ss"
                value-format="dd-MM-yyyy HH:mm:ss"
                :placeholder="$t('general.selectDateHeur')"
              />
            </div>
          </el-form-item>
          <div style="display:flex; flex-direction:row">
            <el-form-item :label="$t('parcelle.produits')" prop="produits">
              <el-select v-model="currentLot.produit_id" value-key="id" style="width: 100%" placeholder="Select">
                <el-option
                  v-for="(item, index) in produits"
                  :key="index"
                  style="width: 100%"
                  :label="item.nom"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="dialogCreateLot=false">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="lotCreating" @click="editing ? updateLot() : createLot()">
            {{ lotCreating ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
// import moment from 'moment';
const lotsResource = new Resource('lots-liste');
const lotsResourceFilter = new Resource('lots');
const zoneResource = new Resource('zones');
const communeResource = new Resource('communes');
const villageResource = new Resource('villages');
const produitResource = new Resource('produits');
const campagneagricoleResource = new Resource('campagne_agricoles');

export default {
  name: 'LotsList',
  components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      drawer: false,
      list: null,
      total: 0,
      detail: false,
      detailData: [],
      detailM: false,
      detailDataM: null,
      loading: true,
      downloading: false,
      lotCreating: false,
      editing: false,
      zones: null,
      communes: null,
      communesBack: null,
      villagesBack: null,
      villages: null,
      zoneFilter: null,
      nomZones: [],
      nomCommunes: [],
      nomVillages: [],
      communeFilter: null,
      villageFilter: null,
      activeList: 'disponibles',
      producteurFilter: null,
      currentLot: {},
      campagneFilter: null,
      campagnes: null,
      activeName: '1',
      produits: [],
      query: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      queryfiltre: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      dialogCreateLot: false,
      currentLotId: 0,
      rules: {
        ste_trans: [{ required: true, message: this.$t('lot.ste_transRequired'), trigger: 'blur' }],
        im_camion: [{ required: true, message: this.$t('lot.im_camionRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.getLotsList();
    this.getProduitsList();
    this.getCampagnesList();
  },
  mounted() {
    this.getLotsList();
    this.getZonesList();
    this.getCommunesList();
    this.getVillagesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    async setLotStatus(id, type){
      const data = await lotsResourceFilter.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    async filterByCampagne(value){
      this.query.campagne_agricole_id = this.campagnes[value].id;
      this.getLotsList();
    },
    handleEditLot(id){
      this.editing = true;
      this.resetNewLot();
      this.currentLot = this.list[0].lots.find(lot => lot.id === id);
      console.log('date_chrgmnt___', this.currentLot);
      this.currentLot.produit_id = this.currentLot.produit.id;

      this.dialogCreateLot = true;
      if (this.$refs['lotForm']) {
        this.$nextTick(() => {
          this.$refs['lotForm'].clearValidate();
        });
      }
    },
    updateLot() {
      this.$refs['lotForm'].validate((valid) => {
        if (valid) {
          this.lotCreating = true;
          lotsResourceFilter.update(this.currentLot.id, this.currentLot).then(response => {
            this.resetNewLot();
            this.handleFilterLots();
            this.$message({
              message: this.$t('lot.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateLot = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.lotCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    createLot() {
      this.$refs['lotForm'].validate((valid) => {
        if (valid) {
          this.lotCreating = true;
          this.currentLot.is_active = true;
          this.currentLot.is_visible = true;
          lotsResourceFilter
            .store(this.currentLot)
            .then(async(response) => {
              this.$message({
                message: this.$t('lot.NewLot') + ' ' + this.currentLot.code + ' ' + this.$t('lot.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentLotId = response.data.id;
              this.resetNewLot();
              this.dialogCreateLot = false;
              this.handleFilterLots();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.lotCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    handlecreateLot() {
      this.editing = false;
      this.resetNewLot();
      this.dialogCreateLot = true;
      if (this.$refs['lotForm']) {
        this.$nextTick(() => {
          this.$refs['lotForm'].clearValidate();
        });
      }
    },
    handleDeleteLot(id, visible, nom) {
      console.log('id : ' + id + 'nom : ' + nom);
      this.$confirm(this.$t('lot.deleteWarn') + ' ' + nom + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer lot']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              lotsResourceFilter.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('lot.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setLotStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    resetNewLot() {
      this.currentLot = {
        code: '',
        ste_trans: '',
        im_camion: '',
        date_chrgmnt: '',
      };
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    async getProduitsList() {
      const { data } = await produitResource.list();
      this.produits = await data;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList) {
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    initSearch() {
      this.query.keyword = '';
      this.activeList = 'disponibles';
      this.query = {
        page: 1,
        limit: 15,
        keyword: '',
      };
      this.handleFilterLots();
    },
    async getCampagnesList() {
      const { data } = await campagneagricoleResource.list(this.queryfiltre);
      this.campagnes = data;
    },
    handleFilterLots() {
      this.query.page = 1;
      this.getLotsList();
    },
    async getSubLotsList(subjet) {
      console.log('subjet', subjet);
      this.loading = true;
      const { data } = await lotsResource.list(this.query);
      console.log('Lots Dta', data);
      var lots = [];
      if (data.length > 0) {
        for (let index = 0; index < Object.values(data[0]).length; index++) {
          var campagne_lots = [];
          var extendedLot = {};
          var element = Object.values(data[0])[index];
          console.log('TETE : ', ['Campagne', Object.keys(data[0])[index]].join(' '));
          extendedLot.campagne = ['Campagne', Object.keys(data[0])[index]].join(' ');
          var individual_data = [];
          if (subjet === 'CORBEILLE') {
            console.log('CORBEILLE_____888');
            for (let i = 0; i < element.length; i++) {
              individual_data = element[i];
              if (!individual_data.is_visible) {
                console.log('STATU : ', subjet, individual_data.status);
                campagne_lots.push(individual_data);
              }
            }
          } else if (subjet === 'DESACTIVER') {
            for (let i = 0; i < element.length; i++) {
              individual_data = element[i];
              if (!individual_data.is_active) {
                console.log('STATU : ', subjet, individual_data.status);
                campagne_lots.push(individual_data);
              }
            }
          } else {
            for (let i = 0; i < element.length; i++) {
              individual_data = element[i];
              if (individual_data.status === subjet) {
                console.log('STATU : ', subjet, individual_data.status);
                campagne_lots.push(individual_data);
              }
            }
          }

          extendedLot.lots = campagne_lots;
          lots.push(extendedLot);
        }
        console.log('LOTS : ', campagne_lots);
        console.log('NEW_FORMAT : ', extendedLot);
        console.log('NEW_ARRAY_FORMAT : ', lots);
        this.list = lots;
      }
      this.loading = false;
    },
    async getLotsList() {
      this.loading = true;
      const { data, total } = await lotsResource.list(this.query);
      console.log('Lots Dta', total);
      this.total = total;
      var lots = [];
      if (data.length > 0) {
        for (let index = 0; index < Object.values(data[0]).length; index++) {
          var campagne_lots = [];
          var extendedLot = {};
          var element = Object.values(data[0])[index];
          console.log('TETE : ', ['Campagne', Object.keys(data[0])[index]].join(' '));
          extendedLot.campagne = ['Campagne', Object.keys(data[0])[index]].join(' ');
          for (let i = 0; i < element.length; i++) {
            var individual_data = element[i];
            campagne_lots.push(individual_data);
          }
          extendedLot.lots = campagne_lots;
          lots.push(extendedLot);
        }
      }
      console.log('NEW_ARRAY_FORMAT : ', lots);
      this.list = lots;
      // this.list = data.reverse();
      console.log('===== EDI');
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getZonesList() {
      const { data } = await zoneResource.list(this.query);
      this.zones = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomZones = _reg;
    },
    async getCommunesList() {
      const { data } = await communeResource.list(this.query);
      this.communes = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomCommunes = _reg;
      this.communesBack = data;
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomVillages = _reg;
      this.villagesBack = data;
    },
    async filterByZone() {
      console.log('la zone : ', this.zoneFilter);
      this.loading = true;
      var requete = this.query;
      this.communeFilter = null;
      this.villageFilter = null;
      const communes = this.communesBack.filter(comm => comm.zone_id === this.zoneFilter);
      this.communes = communes;
      requete.zone_id = this.zoneFilter;
      const { data } = await lotsResource.list(requete);
      this.list = this.processLots(data);
      this.loading = false;
    },
    async filterByCommune() {
      console.log('la Commune : ', this.communeFilter);
      this.loading = true;
      var requete = this.query;
      this.villageFilter = null;
      const villages = this.villagesBack.filter(village => village.commune_id === this.communeFilter);
      this.villages = villages;
      requete.zone_id = this.zoneFilter;
      requete.commune_id = this.communeFilter;
      const { data } = await lotsResource.list(requete);
      this.list = this.processLots(data);
      this.loading = false;
    },
    async filterByVillage() {
      console.log('la Village : ', this.villageFilter);
      this.loading = true;
      var requete = this.query;
      requete.zone_id = this.zoneFilter;
      requete.commune_id = this.communeFilter;
      requete.village_id = this.villageFilter;
      const { data } = await lotsResource.list(requete);
      this.list = this.processLots(data);
      this.loading = false;
    },
    processLots(data) {
      var lots = [];
      if (data.length > 0) {
        for (let index = 0; index < Object.values(data[0]).length; index++) {
          var element = Object.values(data[0])[index];
          var extendedLot = {};
          var campagne_lots = [];
          console.log('TETE : ', ['Campagne', Object.keys(data[0])[index]].join(' '));
          extendedLot.campagne = ['Campagne', Object.keys(data[0])[index]].join(' ');
          for (let i = 0; i < element.length; i++) {
            var individual_data = element[i];
            campagne_lots.push(individual_data);
          }
          extendedLot.lots = campagne_lots;
          lots.push(extendedLot);
        }
        console.log('NEW_ARRAY_FORMAT : ', lots);
        return lots;
      } else {
        return null;
      }
    },
    navigateLot(id) {
      this.$router.push({ path: '/lots/' + id });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}

.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .block {
    float: left;
    min-width: 250px;
  }

  .clear-left {
    clear: left;
  }
}
</style>
